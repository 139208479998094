import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';
import Head from '@/components/Head';
import Footer from '@/components/Footer';
import PageContainer from '@/components/PageContainer';
import ModulesLoop from '@/components/ModulesLoop';

import theme from '@/styles/theme';

export default ({
  data: {
    page: { title, meta, backgroundColor, displayTitle, contentModules },
  },
}) => {
  const textColor = backgroundColor === 'black' ? 'white' : 'black';

  return (
    <PageContainer>
      <Head title={title} meta={meta} />

      {displayTitle ? (
        <Grid.Container>
          <Heading h={2} as="h1" mb={theme.spacing.medium}>
            {title}
          </Heading>
        </Grid.Container>
      ) : (
        <Heading h={1} $hidden>
          {title}
        </Heading>
      )}

      <ModulesLoop contentModules={contentModules} textColor={textColor} />

      <Footer />
    </PageContainer>
  );
};

export const query = graphql`
  query($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      title
      meta {
        ...metaData
      }
      backgroundColor
      displayTitle
      contentModules {
        ...contentModulesData
      }
    }
  }
`;
